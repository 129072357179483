@tailwind base;
@tailwind components;
@tailwind utilities;

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

@layer components {
  .btn-primary {
    @apply bg-purple-600 px-8 py-4 text-white rounded-lg font-semibold text-sm hover:bg-purple-700 duration-200;
  }
  .btn-secondary {
    @apply bg-purple-100 px-8 py-4 text-purple-600 rounded-lg font-semibold text-sm hover:bg-purple-200 duration-200;
  }
  .btn-outlined {
    @apply px-8 py-4 border border-purple-600 text-purple-600 font-semibold rounded-lg text-sm hover:bg-purple-50 duration-200;
  }
  .dropdown {
    @apply absolute shadow-md w-screen rounded-lg overflow-hidden bg-white z-20;
  }
  .input {
    @apply appearance-none block w-full outline-none bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:bg-white focus:border-gray-500 duration-200;
  }
  .input-label {
    @apply block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2;
  }
  .header-link {
    @apply block relative py-4 px-6;
  }
  .header-link:after {
    content: "";
    @apply absolute bottom-0 right-0 w-0 h-1 bg-purple-600 transition-all duration-300 ease-in-out rounded-lg;
  }
  .header-link:hover:after {
    @apply w-full left-0;
  }
}

.indeterminate {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.indeterminate::-webkit-progress-bar {
  background-color: transparent;
}

/* Determinate */
.indeterminate::-webkit-progress-value {
  background-color: currentColor;
  transition: all 0.2s;
}

.indeterminate::-moz-progress-bar {
  background-color: currentColor;
  transition: all 0.2s;
}

.indeterminate::-ms-fill {
  border: none;
  background-color: currentColor;
  transition: all 0.2s;
}

/* Indeterminate */
.indeterminate:indeterminate {
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    transparent 50%,
    currentColor 50%,
    currentColor 60%,
    transparent 60%,
    transparent 71.5%,
    currentColor 71.5%,
    currentColor 84%,
    transparent 84%
  );
  animation: indeterminate 2s infinite linear;
}

.indeterminate:indeterminate::-moz-progress-bar {
  background-color: transparent;
}

.indeterminate:indeterminate::-ms-fill {
  animation-name: none;
}

@keyframes indeterminate {
  0% {
    background-size: 200% 100%;
    background-position: left -31.25% top 0%;
  }
  50% {
    background-size: 800% 100%;
    background-position: left -49% top 0%;
  }
  100% {
    background-size: 400% 100%;
    background-position: left -102% top 0%;
  }
}
